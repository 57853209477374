.leaflet-container {
  height: 80vh;
}

.country-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  z-index: 1000;
  height: 30vh; /* Ajuste la hauteur pour couvrir seulement le bas de la carte */
  overflow-y: auto;
}

.close-button {
  position: absolute; /* Positionnement fixe pour que le bouton reste visible */
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1001; /* Assurez-vous qu'il soit au-dessus du contenu défilant */
}

.country-details-content {
  margin-top: 40px; /* Espace pour éviter le chevauchement avec le bouton */
}

.country_sub_title {
  font-weight: italic;
  color: #00a400;
}