.country-page {
  padding: 20px;
  background-color: #f9f9f9;
  max-width: 800px;
  margin: 0 auto;
}

.country-page img {
  max-width: 200px;
  display: block;
  margin: 10px 0;
}

.country-page h1 {
  color: #333;
}

.country-page h2 {
  margin-top: 20px;
  color: #555;
}