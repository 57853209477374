.main {
    width: 50%;
    margin: auto;
	  min-height: 90vh; /* Assure que la page prend toute la hauteur */
}

/* Media query pour les écrans de taille mobile (moins de 600px) */
@media (max-width: 600px) {
    .main {
        width: 90%; /* Ajuste la largeur à 90% sur les petits écrans */
    }
}

.justify {
    text-align: justify;
}

.large-text {
    font-size: 20px;
    padding-top: 24px;
    padding-bottom: 24px;
}